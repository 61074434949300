<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_tim.trainee_evaluation') }}
              <slot v-if="$route.query.id">
                {{ $t('elearning_config.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="trainee-evaluation" :class="'btn btn-success text-light'">{{ $t('elearning_tim.trainee_evaluation') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="circular_memo_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="circular_memo_no"
                                  v-model="formData.circular_memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  @change="getCircularPublicationInfo"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                          <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_id"
                                  :options="officeList"
                                  id="office_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.training_type_id"
                                  :options="trainingTypeList"
                                  id="training_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_category_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.training_category_id"
                                  :options="trainingCategoryList"
                                  id="training_category_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_title_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.training_title_id"
                                  :options="trainingTitleList"
                                  id="training_title_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Trainee Name" vid="personal_info_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="personal_info_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_tim.trainee_name') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.personal_info_id"
                                  :options="traineeList"
                                  id="personal_info_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Trainee Evaluation Name" vid="trainee_evaluation_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="trainee_evaluation_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_tim.trainee_evaluation_name') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.trainee_evaluation_id"
                                  :options="traineeEvaluationList"
                                  id="trainee_evaluation_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Feedback En"  vid="feedback_en" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="feedback_en"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_tim.feedback_en') }}
                              </template>
                              <b-form-textarea
                                  id="feedback_en"
                                  v-model="formData.feedback_en"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :placeholder="$t('elearning_tim.feedback_en')"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Feedback Bn"  vid="feedback_bn" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="feedback_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_tim.feedback_bn') }}
                              </template>
                              <b-form-textarea
                                  id="feedback_bn"
                                  v-model="formData.feedback_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :placeholder="$t('elearning_tim.feedback_bn')"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Course Name"  vid="course_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="course_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_tim.course_name') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.course_id"
                                  :options="courseList"
                                  id="course_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  @change="getCourseInfo"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{ $t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Total Marks"  vid="total_marks" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="total_marks"
                                slot-scope="{ errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_tim.total_marks') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="total_marks"
                                  v-model="formData.total_marks"
                                  :class="errors[0] ? 'is-invalid': ''"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Attain Marks"  vid="attain_marks" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="attain_marks"
                                slot-scope="{ errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_tim.attain_marks') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="attain_marks"
                                  v-model="formData.attain_marks"
                                  :class="errors[0] ? 'is-invalid': ''"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <div class="my-3 text-right">
                            <button @click="addItem" class="btn btn-sm btn-primary mr-2" type="button"
                            ><i class="fas fa-plus-circle m-0"></i> {{ $t('globalTrans.add_more') }}</button>
                          </div>
                        </b-col>
                      </b-row>
                      <div class="invalid-feedback d-block" v-if="detailsError">
                        Add at least one course info
                      </div>
                      <b-table-simple striped bordered small class="mt-2">
                        <b-thead>
                          <tr>
                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th class="text-center">{{ $t('elearning_tim.course_name') }}</b-th>
                            <b-th class="text-right">{{ $t('elearning_tim.total_marks') }}</b-th>
                            <b-th class="text-right">{{ $t('elearning_tim.attain_marks') }}</b-th>
                            <b-th>{{ $t('globalTrans.action') }}</b-th>
                          </tr>
                        </b-thead>
                        <b-tbody>
                          <template v-if="formData.trainee_evaluation_details.length">
                            <b-tr v-for="(item, index) in formData.trainee_evaluation_details" :key="index">
                              <b-td  class="text-center">{{ index+1 }}</b-td>
                              <b-td class="text-center">{{ currentLocale === 'en' ? item.course_name : item.course_name_bn }}</b-td>
                              <b-td class="text-right">{{ item.total_marks }}</b-td>
                              <b-td class="text-right">{{ item.attain_marks }}</b-td>
                              <b-td class="text-center">
                                <button @click="remove(index)" class="btn btn-sm btn-danger" type="button"><i class="fas fa-window-close m-0"></i></button>
                              </b-td>
                            </b-tr>
                          </template>
                          <template v-else>
                            <b-tr>
                              <b-td colspan="5" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                            </b-tr>
                          </template>
                        </b-tbody>
                      </b-table-simple>
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Remarks En"  vid="remarks_en" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks_en"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('globalTrans.remarks_en') }}
                              </template>
                              <b-form-textarea
                                  id="remarks_en"
                                  v-model="formData.remarks_en"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :placeholder="$t('globalTrans.remarks_en')"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Remarks Bn"  vid="remarks_bn" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('globalTrans.remarks_bn') }}
                              </template>
                              <b-form-textarea
                                  id="remarks_bn"
                                  v-model="formData.remarks_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :placeholder="$t('globalTrans.remarks_bn')"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { traineeEvaluationStore, traineeEvaluationUpdate, traineeEvaluationShow, traineeEvaluationMarkInfo } from '../../api/routes'
import flatpickr from 'flatpickr'
import { trainingApplicationCircularPublicationInfo } from '@/modules/training-e-learning-service/iabm/api/routes'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.$route.query.id) {
      this.getFormData()
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      id: this.$route.query.id,
      loading: false,
      formData: {
        id: '',
        exam_type: 1,
        exam_date: '',
        course_id: 0,
        circular_memo_no: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        personal_info_id: 0,
        trainee_evaluation_id: 0,
        status: 1,
        feedback_en: '',
        feedback_bn: '',
        remarks_en: '',
        remarks_bn: '',
        total_marks: '',
        attain_marks: '',
        trainee_evaluation_details: []
      },
      officeTypeList: [],
      officeList: [],
      trainingCategoryList: [],
      attachments_errors: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: [],
      courseList: [],
      detailsError: ''
    }
  },
  computed: {
    saveBtnName: function () {
      return this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save')
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    traineeList: function () {
      return ''
    },
    traineeEvaluationList: function () {
      return this.$store.state.TrainingElearning.commonObj.traineeEvaluationList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    // 'formData.course_id': function (newValue) {
    //   if (newValue) {
    //     this.getCourseInfo()
    //   }
    // },
    'formData.org_id': function (newValue) {
      this.officeTypeList = this.getOfficeTypeList(newValue)
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'formData.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    }
  },
  methods: {
    async getFormData () {
      this.loading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, traineeEvaluationShow + '/' + this.$route.query.id)
      if (!result.success) {
        this.formData = []
      } else {
        this.formData = result.data
        this.formData.course_id = 0
        this.formData.total_marks = ''
        this.formData.attain_marks = ''
        this.formData.trainee_evaluation_details = this.formData.trainee_evaluation_details.map(item => {
          const course = this.$store.state.TrainingElearning.commonObj.courseList.find(courseObj => courseObj.value === item.course_id)
          const customItem = {
            course_name: course?.text_en,
            course_name_bn: course?.text_bn
          }
          return Object.assign({}, item, customItem)
        })
        this.getCircularPublicationInfo()
        this.getCourseInfo()
      }
      this.loading = false
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
      const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
      if (typeId) {
        return trainingCategoryList.filter(item => item.training_type_id === typeId)
      }
      return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    async getCircularPublicationInfo () {
      this.loading = true
      const result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingApplicationCircularPublicationInfo, { circular_memo_no: this.formData.circular_memo_no, org_id: this.$store.state.dataFilters.orgId })

      if (result.success) {
        this.formData.fiscal_year_id = result.data.fiscal_year_id
        this.formData.training_type_id = result.data.training_type_id
        this.formData.training_category_id = result.data.training_category_id
        this.formData.training_title_id = result.data.training_title_id
        this.formData.org_id = result.data.org_id
        this.formData.office_type_id = result.data.office_type_id
        this.formData.office_id = result.data.office_id
        this.courseList = this.$store.state.TrainingElearning.commonObj.courseList.filter(item => item.circular_memo_no === this.formData.circular_memo_no)
      } else {
        this.formData.fiscal_year_id = ''
        this.formData.training_type_id = ''
        this.formData.training_category_id = ''
        this.formData.training_title_id = ''
        this.formData.org_id = ''
        this.formData.office_type_id = ''
        this.formData.office_id = ''
        this.$toast.error({
          title: 'Error',
          message: this.$t('elearning_iabm.circularInfoNotFoundMsg'),
          color: '#D6E09B'
        })
      }
      this.loading = false
    },
    async getCourseInfo () {
      if (this.formData.course_id) {
        this.loading = true
        const data = {
          personal_info_id: this.formData.personal_info_id,
          course_id: this.formData.course_id
        }
        const result = await RestApi.postData(trainingElearningServiceBaseUrl, traineeEvaluationMarkInfo, data)
        if (!result.success) {
          this.formData.total_marks = ''
          this.formData.attain_marks = ''
          this.$toast.error({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        } else {
          const trData = result.data
          this.formData.total_marks = trData.total_marks
          this.formData.attain_marks = trData.attain_marks
          this.formData = Object.assign({}, this.formData, { total_marks: trData.total_marks, attain_marks: trData.attain_marks }
          )
        }
        this.loading = false
      }
    },
    async createData () {
      this.loading = true
      let result = null

      if (this.formData.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${traineeEvaluationUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, traineeEvaluationStore, this.formData)
      }
      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$router.push('trainee-evaluation')
        this.detailsError = ''
      } else {
        this.$refs.form.setErrors(result.errors)
        if (result.errors.trainee_evaluation_details && result.errors.trainee_evaluation_details.length) {
          this.purchaseRegisterDetailsError = result.errors.trainee_evaluation_details[0]
        }
      }
      this.loading = false
    },
    addItem () {
      if (this.formData.course_id && this.formData.total_marks && this.formData.attain_marks) {
        const courseObj = this.formData.trainee_evaluation_details.find(item => item.course_id === this.formData.course_id)
        if (typeof courseObj === 'undefined') {
          const course = this.$store.state.TrainingElearning.commonObj.courseList.find(item => item.value === this.formData.course_id)
          const obj = {
            course_name: course?.text_en,
            course_name_bn: course?.text_bn,
            course_id: course?.value,
            total_marks: this.formData.total_marks,
            attain_marks: this.formData.attain_marks
          }
          this.formData.trainee_evaluation_details.push(obj)
          this.formData.course_id = ''
          this.formData.total_marks = ''
          this.formData.attain_marks = ''
        }
      }
    },
    remove (key) {
      this.formData.trainee_evaluation_details.splice(key, 1)
    }
  }
}
</script>
